import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import IllustrationSEO from '../components/illustrations/seo'
import { TwitterIcon, TwitterShareButton } from 'react-share'
import { GatsbyImage, getSrc } from "gatsby-plugin-image"

const IngexPage = class extends React.Component {
  onContextMenu(e) {
    e.preventDefault()
  }

  render() {
    const { markdownRemark: illustration } = this.props.data
    const imgSrc = getSrc(illustration.frontmatter.i_m)
    const tag = illustration.frontmatter.tags[0]
    return (
      <Layout isRoot={false} isWebsite={false} noIndex={false} location={this.props.location}>
        <IllustrationSEO
          illustration={illustration}
          imageSrc={imgSrc}
          location={this.props.location}
        />
        <section id="illustration" className="hero is-small">
          <nav className="breadcrumb has-succeeds-separator">
            <ul>
              <li><Link to="/illustrations">イラスト一覧</Link></li>
              <li><Link to={`/illustrations/?tag=${tag}`}>{tag}</Link></li>
            </ul>
          </nav>
          <div className="hero-body px-0">
            <figure className="image" onContextMenu={this.onContextMenu} role="presentation">
              <GatsbyImage
                image={illustration.frontmatter.i_m.childImageSharp.gatsbyImageData}
                alt={illustration.frontmatter.t}
                className="illustration-image"
              />
            </figure>

            <div className="mx-2">
              <h1 className="title is-size-4 mb-0 mt-4">{illustration.frontmatter.t}</h1>
              <div className="tags mb-0 mt-4">
                {illustration.frontmatter.tags && illustration.frontmatter.tags.map((tag) => (
                  <Link to={`/illustrations/?tag=${tag}`} className="tag is-success is-light is-size-7-mobile is-size-6-tablet" key={tag}>#{tag}</Link>
                ))}
              </div>
              <div className="mb-0 mt-2" style={{ whiteSpace: "break-spaces", lineHeight: "1rem" }} dangerouslySetInnerHTML={{ __html: illustration.frontmatter.cd }} />
              <div className="mb-0 mt-4 is-size-7">
                <span><time dateTime={illustration.frontmatter.d}>{illustration.frontmatter.d}</time></span>
                <span className="ml-1">(illustrated by <a href="https://twitter.com/olugho" target="_blank" rel="nofollow noopener noreferrer">olugho</a>)</span>
              </div>
            </div>
          </div>

          <div className="hero-foot mx-2">
            <TwitterShareButton title={illustration.frontmatter.t} url={this.props.location.href}>
              <TwitterIcon size={40} round />
            </TwitterShareButton>
          </div>
        </section>
      </Layout>
    )
  }
}

export const query = graphql`
  query Illustration($id: String!) {
    markdownRemark(
      id: { eq: $id }
    ) {
      id
      frontmatter {
        id
        d(formatString: "YYYY-MM-DD")
        t
        cd
        tags
        i_m {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        m_t
        m_d
      }
    }
  }
`

export default IngexPage
