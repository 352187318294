import React from 'react'
import Helmet from 'react-helmet'
import { useSiteMetadata } from "../../hooks/use-site-metadata"

const IllustrationSEO = ({ illustration, imageSrc, location }) => {
  const { siteMetadata } = useSiteMetadata()
  const ogpFileName = imageSrc.split("/").pop().split(".")[0]
  const imageUrl = `https://www.olugho.com/assets/ogp/${ogpFileName}.jpg`
  return (
    <Helmet>
      <title>{illustration.frontmatter.m_t} | {siteMetadata.title}</title>
      <meta name="description" content={illustration.frontmatter.m_d} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={illustration.frontmatter.t} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:description" content={illustration.frontmatter.t} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={illustration.frontmatter.t} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:description" content={illustration.frontmatter.t} />
      <meta property="og:url" content={location.href} />
      <meta property="og:site_name" content={siteMetadata.title} />
      <meta property="og:locale" content="ja_JP" />
    </Helmet>
  )
}

export default IllustrationSEO
